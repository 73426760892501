import { useTranslation } from 'react-i18next';
import HeadsetIcon from '~/HeadsetIcon';

export const handleSupportMessengerOpen = () => {
  window.location.href = 'mailto:support@propfit.net';
};

export const SupportLink = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={handleSupportMessengerOpen}
        className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
      >
        <span>
          <HeadsetIcon className='color-current mr-3 block h-8 w-8 flex-1' />
        </span>
        {t('support')}
      </div>
    </>
  );
};
