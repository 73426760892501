import {
  faCalendarPlus,
  faClock,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { client } from '~/client';
import { Files } from '~/components/FileLink';
import { TagCombobox, TagEntityType } from '~/components/TagCombobox';
import {
  AddTagsButton,
  TagsBuilder,
} from '~/components/TagCombobox/TagsBuilder';
import { useTags } from '~/components/TagCombobox/tags';
import { redirectNewWindowToLocation } from '~/components/ui/Address';
import { Attributes } from '~/components/ui/Attributes';
import { Avatar } from '~/components/ui/Avatar';
import { BuildingPlaceholder } from '~/components/ui/BuildingPlaceholder';
import { Tooltip } from '~/components/ui/Tooltip';
import {
  useCompleteCustomTaskMutation,
  useResetTaskStatusMutation,
} from '~/generated/graphql';
import { graphql } from '~/gql';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useScrollToFocus } from '~/hooks/useScrollToFocus';
import { formatLocalDate } from '../../formatDate';
import { MemoGallery } from '../Feed/Gallery';
import { Linkify } from '../Feed/Linkify';
import { Job } from '../JobView';
import { TaskList } from '../TaskList';
import { ToggleTaskStatusFn } from '../TaskList/TaskListItem';
import { BookingSection } from './BookingSection';
import { ContactSection } from './ContactSection';
import { getAddress, getContactsAssociatedToJob } from './utils';

export type Contact = {
  id: string;
  name: string;
  email?: string | null;
  image?: string | null;
  phone?: string | null;
};

type WhatProps = {
  job: Job;
  onPlaceNameClick: () => void;
};

type WhoProps = {
  job: Job;
  contacts: Contact[];
};

const JobView_AddTagsToJobMutation = graphql(/* GraphQL */ `
  mutation AddTagsToJob($id: ID!, $tagIds: [ID!]!) {
    addTagsToJob(id: [$id], tagIds: $tagIds) {
      id
      jobTags {
        id
      }
    }
  }
`);

const JobView_RemoveTagsFromJobMutation = graphql(/* GraphQL */ `
  mutation RemoveTagsFromJob($id: ID!, $tagIds: [ID!]!) {
    removeTagsFromJob(id: [$id], tagIds: $tagIds) {
      id
      jobTags {
        id
      }
    }
  }
`);

function Bubble({ children }: { children: ReactNode }) {
  const { isMobile } = useBreakpoint();

  return (
    <div
      className={classNames(
        'flex flex-col leading-5 text-[#002727]',
        isMobile ? 'gap-2' : 'gap-[13px]'
      )}
    >
      {children}
    </div>
  );
}
type SectionProps = {
  children: ReactNode;
  className?: string;
};

function Section({ children, className }: SectionProps) {
  return (
    <div
      className={classNames(
        'flex items-center rounded-[5px] border border-[#bdc9c8] bg-white p-4',
        className
      )}
    >
      {children}
    </div>
  );
}

export function WhereBubble({ job, onPlaceNameClick }: WhatProps) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { isMobile } = useBreakpoint();
  const { pathname } = useLocation();
  const site = job.location?.__typename === 'Site' ? job.location : undefined;
  const address = getAddress(site?.address || '');

  return (
    address && (
      <Bubble>
        <Section
          className={classNames(isMobile ? 'gap-2 px-[13px]' : 'gap-[17px]')}
        >
          {job.site?.image ? (
            <Avatar
              className='h-[75px] w-[75px] rounded-[3px]'
              name={job.location?.name}
              image={job.site?.image}
              size='full'
              square
            />
          ) : (
            <BuildingPlaceholder className='!h-[75px] !w-[75px] rounded-[3px] px-0 text-4xl' />
          )}

          <div className='leading-[17.5px]'>
            <p
              className='cursor-pointer font-semibold leading-[17.5px] hover:underline'
              onClick={() => onPlaceNameClick()}
            >
              {job.location?.name}
            </p>
            <p className='text-[#506262]'>{address?.address}</p>
            <p className='text-[#506262]'>{address?.long}</p>
          </div>

          {site?.address && (
            <div className='ml-auto'>
              {!isMobile && (
                <Tooltip isVisible={isTooltipVisible}>Get Direction</Tooltip>
              )}
              <button
                className={classNames(
                  'h-[42px] w-[42px] rounded-full',
                  isMobile ? 'bg-grey-3' : 'hover:bg-grey-3'
                )}
                onClick={() => redirectNewWindowToLocation(site.address || '')}
                onMouseEnter={() => setIsTooltipVisible(true)}
                onMouseLeave={() => setIsTooltipVisible(false)}
              >
                <FontAwesomeIcon
                  color='#506262'
                  className='text-lg'
                  icon={faLocationDot}
                />
              </button>
            </div>
          )}
        </Section>

        <BookingSection job={job} />
      </Bubble>
    )
  );
}

type AddToCalendarButtonProps = {
  jobId: string;
  isIconMode: boolean;
};

function AddToCalendarButton({ jobId, isIconMode }: AddToCalendarButtonProps) {
  const { isMobile } = useBreakpoint();

  return (
    <>
      {/* Large screen */}
      <a
        className={classNames(
          'ml-auto hidden w-fit items-center gap-2 rounded-full border border-[#bdc9c8] py-[5px] text-sm font-medium text-[#506262]',
          !isIconMode && 'md:flex lg:hidden xl:flex'
        )}
        href={`/api/calendar/jobs/${jobId}`}
      >
        <FontAwesomeIcon icon={faCalendarPlus} className='ml-2' />
        <p className='mr-[11px]'>Add to calendar</p>
      </a>

      {/* Mobile */}
      <a
        className={classNames(
          'ml-auto flex h-[42px] w-[42px] items-center justify-center rounded-full',
          !isIconMode && 'sm:flex md:hidden lg:flex xl:hidden',
          isMobile ? 'bg-grey-3' : 'hover:bg-grey-3'
        )}
        href={`/api/calendar/jobs/${jobId}`}
      >
        <FontAwesomeIcon
          color='#506262'
          className='text-lg'
          icon={faCalendarPlus}
        />
      </a>
    </>
  );
}

export function WhenBubble({ job }: { job: Job }) {
  const { isMobile } = useBreakpoint();
  const { pathname } = useLocation();

  const startAt = formatLocalDate(job, 'scheduledStart');
  const endAt = formatLocalDate(job, 'scheduledEnd');
  const isOrientedVertically =
    pathname.includes('calendar') ||
    pathname.includes('tasks') ||
    pathname.includes('sites');

  return !startAt && !endAt ? null : (
    <Bubble>
      <Section className={classNames(isMobile && 'px-[13px]')}>
        <div
          className={classNames(
            'flex flex-col !items-start gap-[13px]',
            !isOrientedVertically &&
              'md:flex-row md:gap-[21px] lg:flex-col lg:gap-[13px] xl:flex-row xl:gap-[21px]'
          )}
        >
          {startAt && (
            <div className='flex flex-col gap-[5px]'>
              <div className='flex items-center gap-[5px] font-semibold leading-[17.5px]'>
                <FontAwesomeIcon color='#005959' size='sm' icon={faClock} />
                Scheduled Start
              </div>
              <p className='font-normal text-[#506262]'>{startAt}</p>
            </div>
          )}

          {endAt && (
            <div className='flex flex-col gap-[5px]'>
              <div className='flex items-center gap-[5px] font-semibold leading-[17.5px]'>
                <FontAwesomeIcon color='#005959' size='sm' icon={faClock} />
                Scheduled End
              </div>
              <p className='font-normal text-[#506262]'>{endAt}</p>
            </div>
          )}
        </div>
        <AddToCalendarButton jobId={job.id} isIconMode={isOrientedVertically} />
      </Section>
    </Bubble>
  );
}

export function WhoBubble({ job, contacts }: WhoProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { owners, assignees, otherAssignees } = getContactsAssociatedToJob(
    contacts,
    job
  );

  const openContactModal = (id: string) => {
    const trimmedPath = pathname.replace(/\/$/, ''); // Removes trailing slash if present
    const regex = /\/tasks\/\d+/;

    const url = regex.test(trimmedPath)
      ? trimmedPath.replace(regex, `/contacts/${id}`)
      : `${trimmedPath}/contacts/${id}`;
    navigate(url);
  };

  return (
    <Bubble>
      {owners && owners.length > 0 && (
        <ContactSection
          label={t('jobOwner', { count: owners.length })}
          contacts={owners}
          onContactClick={openContactModal}
        />
      )}

      {assignees && assignees.length > 0 && (
        <ContactSection
          label='Assigned to'
          contacts={assignees}
          onContactClick={openContactModal}
        />
      )}

      {otherAssignees.length > 0 && (
        <ContactSection
          label='Includes'
          contacts={otherAssignees}
          onContactClick={openContactModal}
        />
      )}
    </Bubble>
  );
}

export function WhatBubble({
  job,
  contacts,
}: {
  job: Job;
  contacts: Contact[];
}) {
  const { id, tasks, jobTags, notes, attachments } = job;
  const [, completeTask] = useCompleteCustomTaskMutation();
  const [, reset] = useResetTaskStatusMutation();
  const [allTags] = useTags(TagEntityType.Job);
  const tagRef = useScrollToFocus();
  const { isMobile } = useBreakpoint();

  const [isTagBoxOpen, setIsTagBoxOpen] = useState(false);
  const [selectedTagsIds, setSelectedTagsIds] = useState<string[]>(
    jobTags?.map((t) => t.id) || []
  );
  const [isTagLoading, setIsTagLoading] = useState(true);

  useEffect(() => {
    setIsTagLoading(true);
    setTimeout(() => {
      setIsTagLoading(false);
    }, 1500);
  }, []);

  const toggleTaskStatus: ToggleTaskStatusFn = async ({ id, checked }) => {
    const { error } = checked
      ? await completeTask({
          input: { id, date: new Date() },
        })
      : await reset({ id });

    if (error) {
      toast.error(error.message.replace('[GraphQL] ', ''));
      return;
    }
  };

  const updateJobTags = async (tagIds: string[]) => {
    const toRemoveTags = jobTags
      ?.map((a) => a.id)
      .filter((id) => !tagIds.includes(id));

    const toAddTags = tagIds.filter(
      (id: string) => !jobTags?.map((a) => a.id).includes(id)
    );

    await removeTagsFromJob(toRemoveTags);
    await addTagsToJob(toAddTags);
  };

  async function addTagsToJob(tagIds: string[] = []) {
    if (tagIds.length) {
      const result = await client.mutation(JobView_AddTagsToJobMutation, {
        id,
        tagIds,
      });
      const { data, error } = result;

      if (error) {
        toast.error(error.message.replace('[GraphQL] ', ''));
      }
    }
  }

  async function removeTagsFromJob(tagIds: string[] = []) {
    if (tagIds?.length) {
      const result = await client.mutation(JobView_RemoveTagsFromJobMutation, {
        id,
        tagIds,
      });
      const { data, error } = result;

      if (error) {
        toast.error(error.message.replace('[GraphQL] ', ''));
      }
    }
  }

  const haveTags = jobTags && jobTags.length > 0;

  return (
    <Bubble>
      <Section className='px-[13px]'>
        <div className='w-full overflow-x-hidden'>
          <p className='text-lg font-medium leading-5'>Summary</p>
          <div className='mt-[13px] flex flex-col gap-4'>
            {isTagLoading ? (
              <>Loading...</>
            ) : (
              <div ref={tagRef}>
                {haveTags && !isTagBoxOpen ? (
                  <TagsBuilder
                    tags={jobTags}
                    onUpdate={() => {
                      setIsTagBoxOpen(true);
                      setSelectedTagsIds(jobTags?.map((t) => t.id));
                    }}
                  />
                ) : isTagBoxOpen ? (
                  <TagCombobox
                    entityType={TagEntityType.Job}
                    options={allTags}
                    value={allTags.filter((t) =>
                      selectedTagsIds.includes(t.id)
                    )}
                    onChange={(tags, mode) => {
                      const tagIds = tags.map(({ id }) => id);

                      if (mode === 'create') {
                        updateJobTags(tagIds);
                      } else {
                        setSelectedTagsIds(tagIds);
                      }
                    }}
                    dialogMode={true}
                    isOpen={isTagBoxOpen}
                    onClose={() => setIsTagBoxOpen(false)}
                    onSubmit={() => updateJobTags(selectedTagsIds)}
                    containerStyles={classNames(
                      'w-full',
                      isMobile
                        ? '!max-h-[calc(100vh-100px)]'
                        : '!max-h-[calc(100vh-300px)]'
                    )}
                  />
                ) : (
                  <AddTagsButton onClick={() => setIsTagBoxOpen(true)} />
                )}
              </div>
            )}

            {notes && (
              <p className='whitespace-pre-line text-[#272930]'>
                <Linkify>{notes}</Linkify>
              </p>
            )}

            <Files attachments={attachments ?? []} />
            <MemoGallery attachments={attachments ?? []} />
          </div>
        </div>
      </Section>

      {job.attributes && job.attributes.length > 0 && (
        <Section className='px-[13px]'>
          <div className='overflow-x-hidden'>
            <p className='text-lg font-medium leading-5'>Attributes</p>
            <Attributes
              data={{ attributes: job.attributes }}
              contacts={contacts}
            />
          </div>
        </Section>
      )}

      {job.tasks.length > 0 && (
        <Section className='px-[13px]'>
          <div className='flex w-full flex-col gap-5'>
            <p className='text-lg font-medium leading-5'>Tasks</p>
            <div>
              <TaskList job={job} onToggle={toggleTaskStatus} label={''} />
            </div>
          </div>
        </Section>
      )}
    </Bubble>
  );
}
